import { styled } from '@linaria/react';

export const PickerItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  width: 100%;
`;

export const PickerItem = styled.div`
  position: relative;
  min-height: 3.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-xs);
  border-radius: var(--radius-round);
  width: 100%;
  border: 1px solid var(--border-strong);
  padding: var(--spacing-sm) var(--spacing-md);
  transition:
    background-color 200ms ease-in-out,
    box-shadow 100ms ease-in-out;

  svg {
    color: var(--primary);
  }

  &:not([data-disabled='true']):hover {
    box-shadow: var(--elevation-2);
  }

  &[data-selected='true'] {
    padding: calc(var(--spacing-sm) - 1px) calc(var(--spacing-md) - 1px);
    border: 2px solid var(--primary);
    background-color: var(--surface-bright);
  }

  &[data-disabled='true'] {
    opacity: 0.6;
    border: 1px solid var(--border-weak);
  }

  &:not([data-disabled='true']) {
    cursor: pointer;
  }

  &[data-error='true'] {
    border: 1px solid var(--danger);
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xs);

  &[data-alignment='top'] {
    align-items: flex-start;
  }
`;

export const PickerItemContent = styled.div``;

export const Banner = styled.div`
  position: absolute;
  right: -7px;
  top: -4px;
  padding: var(--spacing-2xs) var(--spacing-xs);
  border-radius: 2px;
  background-color: var(--tertiary);

  &::before {
    content: '';
    position: absolute;
    bottom: -12px;
    right: 0;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 14px solid var(--color-spruce-60);
    transform: rotate(0deg);
    clip-path: inset(0 0 0 50%);
  }
`;
