import { ReactNode, useId } from 'react';

import { RMCheckbox } from '../RMCheckbox/RMCheckbox.js';
import { RMText } from '../RMText/RMText.js';

import { Banner, CheckboxWrapper, PickerItem, PickerItemContent, PickerItemWrapper } from './RMPickerItem.styles.js';

export interface RMPickerItemProps {
  label: string | ReactNode;
  value: string;
  selected: boolean;
  disabled?: boolean;
  bold?: boolean | 'bold' | 'medium';
  checkboxAlignment?: 'center' | 'top';
  description?: string | null;
  banner?: string | null;
  error?: string | null;
  onClick: (value: string) => void;
}

export function RMPickerItem({
  label,
  value,
  selected,
  disabled,
  bold = true,
  checkboxAlignment = 'center',
  description,
  banner,
  error,
  onClick,
}: RMPickerItemProps) {
  const id = useId();
  const labelId = 'label-' + id;
  const descriptionId = description != null ? 'description-' + id : undefined;

  return (
    <PickerItemWrapper
      role="radio"
      aria-checked={selected}
      aria-disabled={disabled}
      aria-labelledby={labelId}
      aria-describedby={descriptionId}
      aria-invalid={error != null}
    >
      <PickerItem
        data-selected={selected}
        data-disabled={disabled}
        data-error={error != null}
        onClick={() => {
          if (!disabled) {
            onClick(value);
          }
        }}
      >
        <CheckboxWrapper data-alignment={checkboxAlignment}>
          <RMCheckbox checked={selected} disabled={disabled} disableAccessibility size="sm" />
          <PickerItemContent>
            {typeof label === 'string' ? (
              <RMText id={labelId} as="label" type="sans" size="xs" bold={bold} color="on-surface-primary">
                {label}
              </RMText>
            ) : (
              label
            )}
          </PickerItemContent>
        </CheckboxWrapper>
        {description != null && (
          <RMText id={descriptionId} type="sans" size="xs" color="on-surface-primary">
            {description}
          </RMText>
        )}

        {banner != null && (
          <Banner>
            <RMText type="sans" size="xxs" color="on-tertiary" bold>
              {banner}
            </RMText>
          </Banner>
        )}
      </PickerItem>
      {error && (
        <RMText type="sans" size="xs" color="danger">
          {error}
        </RMText>
      )}
    </PickerItemWrapper>
  );
}
